@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjord+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

// body::-webkit-scrollbar,
// .layout-parent::-webkit-scrollbar,
// #sidebar-parent ::-webkit-scrollbar,
// /* .ant-table-wrapper ::-webkit-scrollbar, */
// .legends-parent::-webkit-scrollbar,
// .custom-dropdown::-webkit-scrollbar,
// .page-layout::-webkit-scrollbar,
// .client-page-parent ::-webkit-scrollbar {
//   display: none;
// }

#sidebar-parent ::-webkit-scrollbar {
  display: none;
}

.ant-table-wrapper ::-webkit-scrollbar,
.admin-scroll::-webkit-scrollbar,
.chart-container-parent ::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 4px;
  width: 4px;
}

.ant-table-wrapper ::-webkit-scrollbar-thumb,
.admin-scroll::-webkit-scrollbar-thumb,
.chart-container-parent ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.admin-scroll::-webkit-scrollbar-track,
.admin-scroll::-webkit-scrollbar-track,
.chart-container-parent ::-webkit-scrollbar-track {
  /* background-color: transparent; */
}

.ant-table-thead > tr > th {
  background: #ededed !important;
  border-radius: 0 !important;
  padding: 5px 8px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f3f3f3;
  text-align: left;
  padding-left: 8px;
  /* width: 100px !important; */
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan]):before {
  display: none !important;
}

.ant-table {
  border-radius: 0 !important;
  /* max-height: 400px !important; */
  /* overflow: auto; */
}

.custom-zebra-table tr:nth-child(odd) {
  background-color: #f0f0f0;
}

.custom-zebra-table-body tbody tr:nth-child(even),
.custom-zebra-table-body thead tr:nth-child(odd),
.ant-custom-zebra-table tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}

.custom-zebra-table-body tbody tr:nth-child(even) td {
  padding: 0.25rem 0.75rem;
}

.ant-modal-content {
  border-radius: 20px !important;
}

.chart-container {
  height: 450px;
  padding-bottom: 2rem;
  /* overflow: auto; */
}

@media screen and (min-width: 640px) {
  .ant-table,
  .tab-title {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 768px) {
  .ant-table,
  .tab-title {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 1024px) {
  .ant-table,
  .tab-title {
    font-size: 0.7rem;
  }

  .custom-card-title {
    color: #54565b;
    font-size: 1vw;
    font-weight: 500;
  }
}

@media screen and (min-width: 1280px) {
  .ant-table,
  .tab-title {
    font-size: 1rem;
  }

  .custom-card-title {
    color: #54565b;
    font-size: 1vw;
    font-weight: 500;
  }
}

/* @media screen and (min-width: 1536px) { */

/* } */

.ant-picker-input > input {
  border: 1px solid #e8ecef;
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
}

.ant-picker-input > input-focused,
.ant-picker-input > input:focus {
  border: 1px solid rgba(215, 30, 14, 0.3);
}

.ant-picker-input > input:hover {
  border: 1px solid rgba(215, 30, 14, 0.3);
}

.ant-picker-focused {
  border: none !important;
}

.ant-picker {
  border: none;
  padding: 0;
  width: 100%;
}

.ant-picker-focused {
  box-shadow: none;
  outline: none;
}

.ant-picker-suffix {
  display: none;
}

.ant-form-item {
  margin: 0;
}

button:focus-visible {
  outline: none !important;
}

.ant-table-column-sorter-inner {
  /* display: none !important; */
}

.ant-table-column-sorters {
  /* justify-content: flex-start !important; */
}

.ant-table-column-title {
  /* flex: none !important; */
}

#page-loader {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}

.Custom-Checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d71e0e;
  border-color: #d71e0e;
  border-radius: 2px;
}

.Custom-Checkbox .ant-checkbox-checked:after {
  border: none;
}

.Custom-Checkbox .ant-checkbox-input:focus + .ant-checkbox-inner,
.Custom-Checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.Custom-Checkbox .ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid #d71e0e;
}

.Custom-Radio .ant-radio-inner:after {
  background-color: #d71e0e;
}

.Custom-Radio .ant-radio-checked .ant-radio-inner {
  border-color: #d71e0e;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 10px 20px !important;
}

.rdrWeekDay,
.rdrMonthName {
  color: #19181a !important;
}

.rdrSelected {
  background-color: #d71e0e !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #d71e0e !important;
}

.rdrDayHovered {
  color: transparent !important;
}

.rdrMonthAndYearWrapper {
  padding-top: 0 !important;
}

.rdrSelected {
  border-radius: 0.4em !important;
}

.ant-input-number {
  border-radius: 0.5rem;
  color: #707673;
  font-size: 1.125rem;
  padding: 0.75rem 0;
  width: 100% !important;
}

.ant-input-number-focused,
.ant-input-number:focus {
  border-color: #e8ecef !important;
  box-shadow: none !important;
}

.ant-input-number-handler-wrap {
  background: transparent;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination-item:hover a {
  color: #d71e0e !important;
}

.ant-pagination-item-active {
  background-color: #d71e0e !important;
  border-color: #d71e0e !important;
  color: white !important;
}

.ant-pagination-item:hover {
  border-color: #d71e0e !important;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  border-color: #d71e0e !important;
  color: #d71e0e !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d71e0e !important;
}

.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #d71e0e !important;
}

#ClientKycVerification .ant-table-thead > tr > th,
#ClientKycVerification .ant-table-tbody > tr > td {
  text-align: center !important;
}

#ClientKycVerification .ant-table-thead > tr > th {
  background-color: transparent !important;
}

#ClientKycVerification .client_verification_bg {
  background: #f0f0f08e !important;
}

#ClientKycVerification thead tr:nth-child(2) th:nth-child(1),
#ClientKycVerification thead tr:nth-child(2) th:nth-child(3) {
  background: #f0f0f08e !important;
}

#Tiptap .ql-container {
  min-height: 10em;
  /* border: none; */
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fafbfc;
}

#Tiptap .ql-toolbar {
  background: #fff;
  /* border: none; */
  height: 56px;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

#Tiptap {
  .ql-picker.ql-font {
    .ql-picker-item {
      font-size: 0;
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }

  .ql-picker.ql-font {
    .ql-active {
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Didot"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Didot"]::before {
    font-family: "GFS Didot", serif;
    content: "GFS Didot" !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Slab"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Slab"]::before {
    font-family: "Roboto Slab", serif;
    content: "Roboto Slab" !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
    font-family: "Raleway", cursive;
    content: "Raleway" !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
    font-family: "Rubik", sans-serif;
    content: "Rubik" !important;
  }

  .ql-picker.ql-font .ql-picker-label[data-value="Fjord"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="Fjord"]::before {
    font-family: "Fjord One", serif;
    content: "Fjord One" !important;
  }

  /* Set content font-families */
  .ql-font-Didot {
    font-family: "GFS Didot";
  }
  .ql-font-Raleway {
    font-family: "Raleway";
  }
  .ql-font-Slab {
    font-family: "Roboto Slab";
  }
  .ql-font-Rubik {
    font-family: "Rubik";
  }
  .ql-font-Fjord {
    font-family: "Fjord One";
  }
}

#custom-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #e8ecef;
  border-radius: 8px;
  color: #707673;
  font-size: 18px;
  height: 50px;
  padding-top: 5px;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

#phone-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #e8ecef;
  border-radius: 8px;
  color: #707673;
  font-size: 18px;
  height: 50px;
  padding-top: 5px;
}
